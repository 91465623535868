import React from 'react'
import Form from '../form.js'
import '../styles/contact.css'

function contact() {
  return (
    <div>
      <div className='contact-content-container'>
        <div className='contact-text'>
          <span><h1>Contact Me</h1></span>
          <Form />
          <p>For all booking and teaching inquiries, please use the above form to contact Olivia
          directly.</p>
        </div>
        <div className='contact-image'></div>
      </div>

    </div>
  )
}

export default contact
