import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/engagement.css'

function engagements() {
  return (
    <div>
      <div className='teaching-container'>
        <div className='teaching-image-container'>
          <div className='teaching-image'></div>
        </div>
        <div className='teaching-text'>
        <h1>Private Voice Lessons</h1>
  <div className='pricing-container'>
    <p>1 hr/$50</p>
    <p>30 min/$30</p>
  </div>
  <p>
    Students may benefit from lessons ranging from thirty minutes to an hour in length. All levels are
    accepted, with lessons promoting mastery in the following areas:
  </p>
  <p className='teaching-bulleted-items'>- Proper support and breathing technique</p>
  <p className='teaching-bulleted-items'>- Consonant articulation and diction (IPA)</p>
  <p className='teaching-bulleted-items'>- Alleviating tension in articulators</p>
  <p className='teaching-bulleted-items'>- Exploration of various styles and repertoire, including classical voice and musical theater</p>
  <p className='teaching-bulleted-items'>- Expression</p>
  <p className='teaching-bulleted-items'>- Vowels, resonance, and projection</p>
  <p className='teaching-bulleted-items'>- Musicianship and musicality</p>
  <p className='teaching-bulleted-items'>- Role preparation</p>
  <p className='teaching-bulleted-items'>- Confidence!</p>
  <p>Email <Link to="/contact">here</Link> to get in touch!</p>
  <p>Lessons are available in person (Indianapolis-Bloomington area), or over Zoom.</p>
        </div>
      </div>
    </div>
  )
}

export default engagements
