import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css';


function home() {
  return (
    
  <div className='homepage-container'>
    <div className='homepage-background-image'>
      <div className='homepage-content'>
        <h2>olivia helman, soprano and musicologist</h2>
        <h3>click below to read more</h3>
        <Link to="/about" className="btn41-43 btn-41">enter</Link>
      </div>  
    </div>      
  </div>
          
    

  )
}

export default home
