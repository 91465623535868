import React, { useState } from "react";
import './styles/form.css'

function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    subject: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const enhancedMessage = `
        Name: ${formData.firstName} ${formData.lastName}
        Subject: ${formData.subject}
        Message: ${formData.message}
      `;

      const response = await fetch('https://formspree.io/f/xannzeyk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: formData.email, message: enhancedMessage })
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          subject: '',
          email: '',
          message: ''
        });
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
  <div className="form-group">
    <div className="name-row">
      <div className="form-group">
      <label>
      First Name <span>(required)</span>
    </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
      <label>
      Last Name <span>(required)</span>
    </label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
    </div>
  </div>
  <div className="form-group">
    <label>
      Email Address <span>(required)</span>
    </label>
    <input
      type="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
    />
  </div>
  <div className="form-group">
    <label>
      Subject <span>(required)</span>
    </label>
    <input
      type="text"
      name="subject"
      value={formData.subject}
      onChange={handleChange}
    />
  </div>
  <div className="form-group">
    <label>
      Message <span>(required)</span>
    </label>
    <textarea
      name="message"
      rows="4"
      value={formData.message}
      onChange={handleChange}
    ></textarea>
  </div>
  <button type="submit" className="btn41-43 btn-41">Submit</button>
</form>
  );
}

export default ContactForm;
