// media.js
import React from 'react';
import '../styles/media.css';
import portrait1 from '../assets/headshot1.jpg';
import portrait7 from '../assets/portrait/IMG_8512.jpg'
import portrait2 from '../assets/headshot2.jpg';
import portrait3 from '../assets/wDude.jpg';
import portrait4 from '../assets/dramatic.jpg';
import portrait5 from '../assets/portrait/IMG_8495.jpg';
import portrait6 from '../assets/portrait/IMG_8498.jpg';
import landscape1 from '../assets/backstage.jpg';
import landscape2 from '../assets/gun.jpg';
import landscape3 from '../assets/kneelingWDude.jpg';
import landscape4 from '../assets/outdoors.jpg'
import landscape5 from '../assets/all_black.jpg'
import landscape6 from '../assets/landscape/IMG_3442.jpg'
import landscape7 from '../assets/landscape/IMG_3476.jpg'
import landscape8 from '../assets/landscape/IMG_3480.jpg'
import landscape9 from '../assets/landscape/IMG_3482.jpg'
import landscape10 from '../assets/landscape/IMG_4498.jpg'
import landscape11 from '../assets/landscape/IMG_4499.jpg'
import landscape12 from '../assets/landscape/IMG_4500.jpg'
import landscape13 from '../assets/landscape/IMG_8492.jpg'
import landscape14 from '../assets/landscape/IMG_8493.jpg'
import landscape15 from '../assets/landscape/IMG_8496.jpg'
import landscape16 from '../assets/landscape/IMG_8497.jpg'
import landscape17 from '../assets/landscape/IMG_8499.jpg'
import landscape18 from '../assets/landscape/IMG_8500.jpg'
import landscape19 from '../assets/landscape/IMG_8501.jpg'
import landscape20 from '../assets/landscape/IMG_8504.jpg'
import landscape21 from '../assets/landscape/IMG_8505.jpg'
import landscape22 from '../assets/landscape/IMG_8506.jpg'
import landscape23 from '../assets/landscape/IMG_8507.jpg'
import landscape24 from '../assets/landscape/IMG_8508.jpg'
import landscape25 from '../assets/landscape/IMG_8509.jpg'
import landscape26 from '../assets/landscape/IMG_8510.jpg'



// Sample image paths
const Images = [portrait1, portrait3, portrait2, portrait4, portrait5, portrait6, 
portrait7, landscape1, landscape2, landscape3, landscape4, landscape5, landscape6, 
landscape7, landscape8, landscape9, landscape10, landscape11, landscape12, landscape13, 
landscape14, landscape15, landscape16, landscape17, landscape18, landscape19, landscape20, 
landscape21, landscape22, landscape23, landscape24, landscape25, landscape26];


function Media() {
  
  return (
    <div>
      <div className="media-container">
      <div className="image-grid">
        {Images.map((image, index) => (
          <div key={index} className="image-wrapper">
            <img src={image} alt={`Image ${index + 1}`} className="gallery-image" />
          </div>
        ))}
      </div>
    </div>
  </div>
  );
}

export default Media;
