import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './navbar.js'; 
import Home from './pages/home';
import About from './pages/about.js';
import Engagements from './pages/engagements.js';
import Media from './pages/media.js';
import Contact from './pages/contact.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/about' Component={About} />
          <Route path='/teaching' Component={Engagements} />
          <Route path='/gallery' Component={Media} />
          <Route path='/contact' Component={Contact} />
        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
