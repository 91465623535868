import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import './styles/navbar.css';

function Navbar() {
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const getActiveClass = (path) => (location.pathname === path ? 'active-link' : '');

  return (
    <nav className="navbar">
      <div className="navbar-inner">
        <div className="navbar-container">
          <div className="navbar-left">
            <a href="https://www.instagram.com/0liviaah?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
              <InstagramIcon className="icon"/>
            </a>
            <a href="https://www.facebook.com/olivia.helman.146/">
              <FacebookIcon className="icon"/>
            </a>
          </div>
        <div className='navbar-center'>
          <div className='navbar-title'>
            <Link to="/">
              <h2>olivia helman</h2>
            </Link>
          </div>
          <div className={`navbar-links ${isMobileMenuOpen}`}>
              <Link to="/" className={getActiveClass('/')}>home</Link>
              <Link to="/about/" className={getActiveClass('/about/')}>about</Link>
              <Link to="/teaching/" className={getActiveClass('/teaching/')}>teaching</Link>
              <Link to="/gallery/" className={getActiveClass('/gallery/')}>gallery</Link>
              <Link to="/contact/" className={getActiveClass('/contact/')}>contact</Link>
            </div>
        </div>
        <div className='navbar-right'>
          <div className='navbar-right-button-container'>
          <button
  className={`menu ${isMobileMenuOpen ? 'opened' : ''}`}
  onClick={() => {
    toggleMobileMenu();
  }}
  aria-label="Toggle Menu"
  aria-expanded={isMobileMenuOpen}
>
  <svg width="40" height="40" viewBox="0 0 100 100">
    <path
      className="line line1"
      d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
    />
    <path className="line line2" d="M 20,50 H 80" />
    <path
      className="line line3"
      d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
    />
  </svg>
</button>

          </div>
        </div>
      </div>
      {/* Mobile Menu Dropdown */}
      {isMobileMenuOpen && (
          <div className="mobile-menu">
            <Link to="/" onClick={toggleMobileMenu}>home</Link>
            <Link to="/about/" onClick={toggleMobileMenu}>about</Link>
            <Link to="/teaching/" onClick={toggleMobileMenu}>teaching</Link>
            <Link to="/gallery/" onClick={toggleMobileMenu}>gallery</Link>
            <Link to="/contact/" onClick={toggleMobileMenu}>contact</Link>
            <div className='mobile-menu-icons'>
              <a href="https://www.instagram.com/0liviaah?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                <InstagramIcon className="icon"/>
              </a>
              <a href="https://www.facebook.com/olivia.helman.146/">
                <FacebookIcon className="icon"/>
              </a>
            </div>
          </div>
        )}
    </div>
    </nav>
  );
}

export default Navbar;
