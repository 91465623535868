import React from 'react'
import '../styles/about.css'

function about() {
  return (
    <div className='about-content-container'>
      <div className='about-image-container'>
        <div className='about-image'></div>
        <div className='about-image-wrapper'>
          <p className='omitted-margin'>American coloratura soprano, composer, and musicologist, Olivia Helman is no stranger to the new,
  exciting, and interdisciplinary, paving the way for integrated performer-scholars while specializing in a
  range of genres from baroque chamber music to contemporary performance art.</p>
            <p>Especially interested in the performance of “the very new and the very old,” Helman feels most at home in
  early and contemporary repertoire, finding joy in collaborations within chamber contexts that uplift diverse
  soundscapes of the past and present. Helman participated in the concert premier of Griffin Candey’s La
  Casa de Bernarda Alba (Ensemble), and originated the role of Evelyn in Lisa DeSpain’s The Men I Am
  Not Married To as a part of the Operas [in Place] festival, produced by the Baldwin Wallace University in
  collaboration with On Site Opera and Cleveland Opera Theater. Helman dabbles in composition herself,
  creating a series of event scores with her peers in Dr. Matthew Rahaim’s “Improvising Relationality”
  seminar at the University of Minnesota. Inspired by artists such as Yoko Ono, Pauline Oliveros, Pedram
  Baldari, and Molly Sturges, her visual and written scores encompass perceptions of and meditations on
  individual experiences of movement, stillness, and sensation by transcending boundaries between excess
  and minimalism.</p>     
        </div>
      </div>
      <div className='about-text'>
          <p className='omitted-margin'>Alongside her performance endeavors, Helman is a passionate advocate for the synthesis between
academic and artistic pursuits. She has been able to draw on knowledge in several fields to achieve a
number of significant musicological outcomes, including a publication in BACH: The Journal of the
Riemenschneider Bach Institute entitled “The Marchesi Legacy: Selma Riemenschneider and Vocal
Pedagogy” (Spring 2022), as well as several public lectures completed during her time as a member of
the Riemenschneider Bach Institute (RBI) Scholars Program at Baldwin Wallace University. Her research
primarily centers on voice performance practice and opera with special focuses on women musicians and
representations of gender and sexuality in Baroque opera. Helman continues to study the treatments,
functions, and characterizations of high-voiced singers within Handel’s Royal Academy operas, arguing
for a vocal approach (as opposed to gendered approach) in casting roles originally composed for castrati.</p>
          <p>On the stage, Helman’s operatic credits include Susanna (Le Nozze di Figaro), Alcina (Alcina), Drusilla
(L’incoronazione di Popper), and Ines (Il Trovatore). Helman has also appeared with the Baldwin Wallace
Conservatory Motet Choir in J.S. Bach’s St. Matthew's Passion, Christmas Oratorio, and Mass in B Minor.
She currently performs frequently as a member of Indiana University’s early music ensemble, Concentus,
and University Chorale.</p>
          <p>Helman holds degrees in voice performance and music history in literature from Baldwin Wallace
Conservatory of Music, where she was awarded the Martha Goldsworthy Arnold Talent Scholarship and
the first prize award in the NATS Great Lakes Regional Conference. Helman is an active member of the
Mu Phi Epsilon fraternity, and currently resides (with her beloved cat, Tilly), in Bloomington, Indiana while
pursuing her master’s degree in voice performance at Indiana University.</p>
        </div>
      </div>
  )
}

export default about
